.ol-dragbox {
	background-color: rgba(255,255,255,0.4);
	border-color: rgba(100,150,0,1);
}

/* START - ZoomSlider */
/* .ol-zoom .ol-zoom-out {
	margin-top: 204px;
}
.ol-zoomslider {
	background-color: transparent;
	top: 2.3em;
}

.ol-touch .ol-zoom .ol-zoom-out {
	margin-top: 212px;
}
.ol-touch .ol-zoomslider {
	top: 2.75em;
}

.ol-zoom-in.ol-has-tooltip:hover [role=tooltip],
.ol-zoom-in.ol-has-tooltip:focus [role=tooltip] {
	top: 3px;
}

.ol-zoom-out.ol-has-tooltip:hover [role=tooltip],
.ol-zoom-out.ol-has-tooltip:focus [role=tooltip] {
	top: 232px;
} */
/* END - ZoomSlider */


.map-control-5 {
	/* top: .5em; */
	top: .5em;
	right: .5em;
}
.map-control-3 {
	/* top: .5em; */
	top: 2.5em;
	right: .5em;
}
.map-control-1 {
	/* top: .5em; */
	top: 4.5em;
	right: .5em;
}
.map-control-2 {
	/* top: .5em; */
	top: 6.5em;
	right: .5em;
}
.map-control-4 {
	/* top: .5em; */
	top: 8.5em;
	right: .5em;
}

.ol-button.ol-active button{
  background: rgba(60, 136, 0, 0.7);
}

