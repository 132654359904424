@import '~antd/dist/antd.css';

html,
body,
#root {
	height: 100%;
}

.ant-layout-sider {
	background-color: #fff;
}

/*rect.f1 {
	fill: #2749FA;
}
rect.f2 {
	fill: #82CA3F;
}
rect.f3 {
	fill: #FEDD23;
}
rect.f0 {
	fill: #000000;
}
rect.tot {
	fill: #FC006D;
}*/

/*
 * APE STYLE
 */

/*.ant-layout-header {
  background-color: #e8e8e8;
}

.ant-menu, .ant-layout-sider {
  background-color: #ddd;
}

.ant-layout-sider-trigger {
  background-color: #377fbd;
}*/

.ant-layout-header {
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
	z-index: 1;
}

.react-grid-Viewport .react-grid-Canvas {
	background-color: #f6f6f6;
}

// fix ant checkbox :: start

.ant-checkbox-wrapper {
	align-items: center;
}

.ant-checkbox {
	top: initial;
}

// fix ant checkbox :: end

// improve Contratti Collapse :: start

.contratti-collapse {
	background-color: transparent;
	border: none;
}
.contratti-collapse .ant-collapse-content-box {
	padding: 0px;
}
.contratti-collapse .ant-collapse-item {
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	margin-bottom: 10px;
}
.contratti-collapse .ant-collapse-item:last-child {
	margin-bottom: 0;
}

// improve Contratti Collapse :: end