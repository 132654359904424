/* react-data-grid functional fixes --- START */

.react-grid-Main {
	height: 100%;
}
.react-grid-Container {
	height: 100%;

	/* the following require container to be positioned 'relative' */
	position: absolute;
	top: 0;
	left: 0;
}
.react-grid-Grid {
	min-height: 100% !important;

	user-select: none;
}
.react-grid-Canvas {
	height: 100% !important;
}

/* react-data-grid functional fixes --- END */

/* react-data-grid stylistic fixes --- START */

.react-grid-Main {
	outline: none;
}

.react-grid-row-group > div {
	padding: 5px !important;
	.row-expand-icon {
		font-size: 12px;
		line-height: 25px;
		margin-right: 5px;
	}
}
.rdg-selected {
	border: none;
}

.react-grid-Main {
	font-size: 85%;
	line-height: 2 * 0.85;
}

.react-grid-Cell.react-grid-Cell-CUSTOM {
	padding: 0;
}

.react-grid-Main .drag-handle {
	display: none;
}

.react-grid-Grid {
	border: none; // 1px solid #dddddd
}
/* react-data-grid stylistic and functional fixes --- END */