.ol-control > *:not(:first-child),
.ol-control *:not(.ol-switch-control) > *:not(:first-child) {
  margin-top: 4px;
}

.ol-control .ol-switch-control > .ol-button:not(:first-child) button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ol-control .ol-switch-control > .ol-button:not(:last-child) button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ol-button.ol-active button {
  background: rgba(60, 136, 0, 0.7);
}

.ol-control-bar {
  z-index: 1000;
}

.ol-layer-switcher .ol-layer-switcher-popup {
  position: absolute;
  // background-color: rgba(255, 255, 255, 0.4);
  right: 33px;
  top: -4px;
  border-radius: 4px;
  color: black;
  font-size: 0.9rem;
  padding: 10px;
  list-style-type: none;
  width: 170px;
  line-height: 1;
  font-weight: normal;
  text-align: left;
  font-family: sans-serif;
}

// ANTD FIXES
.ant-checkbox-wrapper span:not(.ant-checkbox) {
  display: inline-block;
  vertical-align: middle;
}